.flex-grow {
  flex-grow: 1;
}

.flex_col-ae-jc {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
}

.flex_col-ac-jc {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.flex_col-ac-jsb {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
}

.flex_col-as-jc {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
}

.flex_col-as-js {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex_col--jc {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.flex_col--js {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.flex_col--jsb {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.flex_row--jc {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.flex_row-ac-jc {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.flex_row-ac-js {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.flex_row-ac-jsb {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.flex_row-ac-je {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}

.flex_col-ac-je {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}

.flex_row-ac-jsa {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
}

.flex_row-ac-jse {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
}

.flex_col-ae-js {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-start;
}

.flex_row-as-js {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex_row--js {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.flex_row--jsb {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.flex_rwrap--js {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.flex_rwrap--jsb {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.flex_rwrap-ac-jc {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.flex_rwrap-ac-je {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
}

.flex_rwrap-ac-js {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.flex_rwrap-ac-jsa {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}

.flex_rwrap-ac-jsb {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.flex_rwrap--js {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.flex_rwrap-as-jsb {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}
