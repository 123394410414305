$max-410: "only screen and (max-width: 410px)";
$max-550: "only screen and (max-width: 550px)";
$max-600: "only screen and (max-width: 600px)";
$max-685: "only screen and (max-width: 685px)";
$max-770: "only screen and (max-width: 770px)";
$min-771: "only screen and (min-width: 771px)";
$min-800: "only screen and (min-width: 800px)";
$max-860: "only screen and (max-width: 860px)";
$max-960: "only screen and (max-width: 960px)";
$min-961: "only screen and (min-width: 961px)";
$max-1000: "only screen and (max-width: 1000px)";
$max-1024: "only screen and (max-width: 1024px)";
$max-1089: "only screen and (max-width: 1089px)";
$min-1090: "only screen and (min-width: 1090px)";
$max-1169: "only screen and (max-width: 1169px)";
$max-1250: "only screen and (max-width: 1250px)";
$max-1350: "only screen and (max-width: 1350px)";
$max-1630: "only screen and (max-width: 1630px)";
$max-1998: "only screen and (max-width: 1998px)";
$min-1999: "only screen and (min-width: 1999px)";
$media-ie: "all and (-ms-high-contrast: none), (-ms-high-contrast: active)";
