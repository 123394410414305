@mixin single-text-shadow($size1, $size2, $size3, $size4, $color) {
  -webkit-box-shadow: $size1 $size2 $size3 $size4 rgba($color, 0.5);
  -moz-box-shadow: $size1 $size2 $size3 $size4 rgba($color, 0.5);
  -ms-box-shadow: $size1 $size2 $size3 $size4 rgba($color, 0.5);
  -o-box-shadow: $size1 $size2 $size3 $size4 rgba($color, 0.5);
  box-shadow: $size1 $size2 $size3 $size4 rgba($color, 0.5);
}

@mixin opacity($strength) {
  opacity: $strength;
}

@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  -ms-box-sizing: $value;
  -o-box-sizing: $value;
  box-sizing: $value;
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  -ms-appearance: $value;
  -o-appearance: $value;
  appearance: $value;
}

@mixin border-radius($size) {
  -webkit-border-radius: $size;
  -moz-border-radius: $size;
  -ms-border-radius: $size;
  -o-border-radius: $size;
  border-radius: $size;
}

@mixin inline-block {
  display: -webkit-inline-block;
  display: -moz-inline-block;
  display: -ms-inline-block;
  display: -o-inline-block;
  display: inline-block;
}

@mixin single-transition($prop, $time) {
  -moz-transition: $time $prop ease;
  -webkit-transition: $time $prop ease;
  -ms-transition: $time $prop ease;
  -o-transition: $time $prop ease;
  transition: $time $prop ease;
}

@mixin transition-property($prop) {
  -webkit-transition-property: $prop;
  -moz-transition-property: $prop;
  -ms-transition-property: $prop;
  -o-transition-property: $prop;
  transition-property: $prop;
}

@mixin transition-duration($time) {
  -webkit-transition-duration: $time;
  -moz-transition-duration: $time;
  -ms-transition-duration: $time;
  -o-transition-duration: $time;
  transition-duration: $time;
}
