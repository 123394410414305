.cart-collaterals {
  display: none;
}

.amscheckout {

  &-main {
    margin-top: 20px;
  }

  &-tab-guest-checkout,
  &-tab-login {
    opacity: 0.45;
  }
}

.amscheckout .amasty {

  &-scheckout-login-buttons {

    .tabs {

      &_title {
        list-style: none;
        position: relative;
        z-index: 2;
        padding: 0;

        li {
          border-radius: 4px;

          & + li {
            margin-left: 10px;
          }

          a {
            display: block;
            padding: 4px 10px;
            text-decoration: none;
            color: $base-white;
            @include transition(0.2);
          }

          button {
            margin: 5px 7px;
            @include transition(0.2);

            a {
              display: block;
              padding: 4px 10px;
              text-decoration: none;
              font-weight: bold;
              color: $base-white;
              font-size: 12px;
            }
          }


          &.active {
            opacity: 1;

            a {
              display: block;
              padding: 4px 10px;
              text-decoration: none;
            }
          }
        }
      }
    }

    li.item {
      margin: 0px -1px 0 0;
      float: left;
    }
  }

  &-overlay {
    width: 100%;
    height: 100%;
    background: $base-white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 400;
  }

  &-label-notifications {
    padding: 10px 0 10px 0;
  }
}

.centinel {
  height: 100%;

  .authentication {
    height: 100%;

    #centinel_authenticate_iframe {
      width: 100%;
      height: 100%;
    }
  }
}

